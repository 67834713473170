<div class="header">
    <div class="left-sec">
        <ng-container *ngIf="welcomeUserMsg">
            <h1>Dashboard</h1>
            <p>Hello {{userName}}, Welcome Back!</p>
        </ng-container>
        <h1 *ngIf="manageUsersHeading">
            <!-- Regular Users -->
            View Customers
        </h1>
        <h1 *ngIf="businessUsersHeading">
            <!-- Business Users -->
            View Vendors
        </h1>
        <h1 *ngIf="manageEmergencyHeading">Manage Emergency</h1>
        <h1 *ngIf="dashboardHeading">Dashboard</h1>
        <h1 *ngIf="changePasswordHeading">Change Password</h1>
        <h1 *ngIf="revenueGeneratedHeading">Revenue Generated</h1>
        <h1 *ngIf="registeredUsersHeading">Registered Users</h1>
        <h1 *ngIf="notificationHeading">Broadcast Notification</h1>
        <h1 *ngIf="manageAdminsHeading">Manage Admins</h1>
        <h1 *ngIf="manageAdsHeading">View Ad Requests</h1>
    </div>

    <div class="right-sec">
        <div class="btn-group" dropdown placement="bottom right">
            <button id="button-alignment" dropdownToggle type="button" class="btn dropdown-toggle"
                aria-controls="dropdown-alignment"><mat-icon>settings</mat-icon> Settings
            </button>
            <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                aria-labelledby="button-alignment">
                <li style="cursor: pointer;" role="menuitem"><a class="dropdown-item admin-dropdown"
                        (click)="openModal(template)">View Profile</a></li>
                <li role="menuitem"><a class="dropdown-item admin-dropdown" routerLink="/edit-profile">Change
                        Password</a></li>
                <li role="menuitem"><a class="dropdown-item admin-dropdown"
                        (click)="openLogoutModal(template1)">Logout</a></li>
            </ul>
        </div>

    </div>
</div>

<div id="partners">
    <ng-template #template>
        <div class="modal-header">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide(); reset()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body delete-modal" *ngIf="!del">
            <h3 *ngIf="update">Update Profile</h3>
            <div class="partner-form custom-form-field-container">
                <form [formGroup]="interestForm" class="row g-3 form-group">
                    <div class="col-md-12">
                        <mat-form-field class="example-full-width inputboxsProfile" appearance="outline">
                            <mat-label>Email</mat-label>
                            <input type="text" matInput placeholder="Email" formControlName="email">
                        </mat-form-field>
                    </div>
                    <div class="col-md-12">
                        <mat-form-field class="example-full-width inputboxsProfile" appearance="outline">
                            <mat-label>First Name</mat-label>
                            <input type="text" matInput placeholder="First Name" (keypress)="charOnly($event)" formControlName="firstName">
                            <div *ngIf="submitted && f.firstName.errors">
                                <mat-error *ngIf="f.firstName.errors?.required">First name name is required.</mat-error>
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="col-md-12">
                        <mat-form-field class="example-full-width inputboxsProfile" appearance="outline">
                            <mat-label>Last Name</mat-label>
                            <input type="text" matInput placeholder="Last Name" (keypress)="charOnly($event)" formControlName="lastName">
                            <div *ngIf="submitted && f.lastName.errors">
                                <mat-error *ngIf="f.lastName.errors?.required">First name name is required.</mat-error>
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="col-md-12">
                        <mat-form-field class="example-full-width inputboxsProfile" appearance="outline">
                            <mat-label>Phone Number</mat-label>
                            <input type="text" matInput placeholder="Phone Number" maxlength="11" (keypress)="numberOnly($event)" formControlName="phoneNumber">
                            <div *ngIf="submitted && f.phoneNumber.errors">
                                <mat-error *ngIf="f.phoneNumber.errors?.required">Phone is required.</mat-error>
                                <mat-error *ngIf="f.phoneNumber.errors?.minlength">Phone Number must have atleast 7 digits.</mat-error>
                            </div>
                        </mat-form-field>
                    </div>
                </form>
            </div>

            <div class="modal-btn d-flex justify-content-around mt-3">
                <button class="btn btn-cancel" (click)="cancel()" *ngIf="submit || update || del">Cancel</button>
                <button class="btn btn-blue" (click)="edit()" [disabled]="userData?.firstName==interestForm.value.firstName &&  
                userData?.lastName==interestForm.value.lastName && userData?.phone==interestForm.value.phoneNumber   
                || interestForm.invalid ">Update User</button>
                <button class="btn btn-blue" (click)="editPopup()" *ngIf="view">Edit</button>
            </div>
        </div>

    </ng-template>
</div>

<ng-template #template1>
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body text-center delete-modal align-items-center">
        <img src="../../../assets/images/create-successfull-img.svg" alt="">
        <p>Are you sure you <br>want to logout?</p>
        <div class="modal-btn d-flex justify-content-around">
            <button class="btn btn-red" (click)="cancel()" style="color: #000;">No</button>
            <button class="btn btn-blue" (click)="confirm()">Yes</button>
        </div>
    </div>

</ng-template>