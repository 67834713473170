<div class="sidebar">
  <div class="logo"><img src="../../../assets/sidbar_icons/sidebar_logo.svg" alt="logo"></div>
  <div class="header-links">

    <a routerLink="/dashboard" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Black/dashboard.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/White/dashboard_white.svg" alt="" height="24" width="24">
      </span>Reporting</a>


    <a routerLink="/customers" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Black/Care.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/White/users-white.svg" alt="" height="24" width="24">
      </span>
      <!-- View Regular Users -->
      View Customers
    </a>


    <a routerLink="/vendors" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Black/Manage Business.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/White/Manage_Business_White.svg" alt="" height="24" width="24">
      </span>
      <!-- View Business Users -->
      View Vendors
    </a>


      <a routerLink="/ad-requests" routerLinkActive="active">
        <span class="sidebar-icon">
          <img class="black-icon" src="../../../assets/sidbar_icons/Black/Manage Newsfeed.svg" alt="" height="24" width="24">
          <img class="white-icon" src="../../../assets/sidbar_icons/White/Manage_Feeds_White.svg" alt="" height="24" width="24">
        </span>View Ad Requests</a>
        
        <a routerLink="/manage-admins" routerLinkActive="active" *ngIf="currentUser?.role!='admin'">
          <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/sidbar_icons/Black/Manage Newsfeed.svg" alt="" height="24" width="24">
            <img class="white-icon" src="../../../assets/sidbar_icons/White/Manage_Feeds_White.svg" alt="" height="24" width="24">
          </span>Manage Admins</a>

    <!-- <a routerLink="/manage/emergency" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/xodus/blackEmergency.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/xodus/whiteEmergency.svg" alt="" height="24" width="24">
      </span>Manage Emergency</a>

    <a routerLink="/notifications/create" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/xodus/notifications_black.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/xodus/Notifications_White.svg" alt="" height="24" width="24">
      </span>Broadcast Notification </a> -->

    <!--<a routerLinkActive="active" class="dropdown-item admin-dropdown" id="logout" (click)="openModal(template)">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/Log Out Black.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/icons/Log Out Black.svg" alt="" height="24" width="24">
      </span>
      Logout</a> -->
  </div>
</div>

<!-- Logout fonfirm -->
<ng-template #template>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center delete-modal align-items-center">
    <img src="../../../../../assets/images/create-successfull-img.svg" alt="">
    <p>Are you sure you <br>want to logout?</p>
    <div class="modal-btn d-flex justify-content-around">
      <button class="btn btn-red" (click)="cancel()" style="color: #000;">No</button>
      <button class="btn btn-blue" (click)="confirm()">Yes</button>
    </div>
  </div>

</ng-template>